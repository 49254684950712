<template>
  <ModuleSubPage title="USER_PROFILE.CHANGE_PASSWORD.TITLE">
    <v-sheet class="pa-6" elevation="2">
      <v-row>
        <v-col cols="12">
          <PasswordCreate
            v-model="password"
            @complete="changePasswordState($event)"
            :mfa="showMFAtokenField"
          />
        </v-col>
      </v-row>

      <v-row>
        <v-spacer />
        <v-col cols="auto">
          <BaseButton :disabled="deactivateButton" :loading="isLoading" @click="changePassword">
            {{ $t("USER_PROFILE.CHANGE_PASSWORD.SAVE_BTN") }}
          </BaseButton>
        </v-col>
      </v-row>
    </v-sheet>
  </ModuleSubPage>
</template>

<script>
import ModuleSubPage from "@components/Layout/ModuleSubPage.vue";
import PasswordCreate from "@components/FormElements/Password/Create";
import { mapActions } from "vuex";
import formValidation from "@/mixins/formValidation";

export default {
  name: "ChangePassword",

  mixins: [formValidation],

  data() {
    return {
      password: {
        password: null,
        passwordConfirm: null,
        mfaToken: null,
      },
      passwordComplete: false,
      isLoading: false,
      showMFAtokenField: false,
    };
  },

  components: {
    ModuleSubPage,
    PasswordCreate,
  },

  computed: {
    deactivateButton() {
      return this.isLoading || !this.passwordComplete;
    },
  },

  methods: {
    ...mapActions("notifications", ["notify"]),

    initData() {
      this.password.password = null;
      this.password.passwordConfirm = null;
      this.password.mfaToken = null;
      this.showMFAtokenField = false;
    },

    changePasswordState(event) {
      this.passwordComplete = event;
    },

    async savePassword() {
      try {
        await this.$axios.authUser.changePassword({
          password: this.password.password,
          passwordConfirmation: this.password.passwordConfirm,
          token: this.password.mfaToken,
        });
        await this.notify({
          type: "success",
          message: "USER_PROFILE.CHANGE_PASSWORD.SUCCESS",
        });
        this.initData();
      } catch (err) {
        this.setValidationErrors(err);
        console.error(err);
      }
    },

    async createMFAToken() {
      try {
        await this.$axios.authUser.createMFAToken();
        this.showMFAtokenField = true;
      } catch (err) {
        console.error(err);
      }
    },

    async changePassword() {
      this.isLoading = true;

      try {
        if (!this.showMFAtokenField) {
          await this.createMFAToken();
        } else {
          await this.savePassword();
        }
      } finally {
        this.isLoading = false;
      }
    },
  },
};
</script>
